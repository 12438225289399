.layout-sidebar {
    position               : fixed;
    width                  : 240px;
    height                 : calc(100vh - 5rem);
    z-index                : 999;
    overflow-y             : auto;
    -webkit-user-select    : none;
    user-select            : none;
    top                    : 4.9rem;
    left                   : 0rem;
    transition             : left 0.2s, -webkit-transform 0.2s;
    transition             : transform 0.2s, left 0.2s;
    transition             : transform 0.2s, left 0.2s, -webkit-transform 0.2s;
    background-color       : var(--surface-overlay);
    border-top-right-radius: 3px;
    padding                : 1.5rem;
    box-shadow             : 0px 13px 10px 3px #ddd;




    // position: fixed;
    // width: 300px;
    // height: calc(100vh - 9rem);
    // z-index: 999;
    // overflow-y: auto;
    // user-select: none;
    // top: 7rem;
    // left: 2rem;
    // transition: transform $transitionDuration, left $transitionDuration;
    // background-color: var(--surface-overlay);
    // border-radius: 12px;
    // padding: 1.5rem;
    // box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08)
}

// .layout-sidebar {
//     max-height : 300px;
//     overflow   : auto;
//     padding-top: 0;
// }

/* width */
.layout-sidebar::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.layout-sidebar::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.layout-sidebar::-webkit-scrollbar-thumb {
    // background   : #4CE5B1;
    background   :#5052E2;
    border-radius: 10px;
}

/* Handle on hover */
.layout-sidebar::-webkit-scrollbar-thumb:hover {
    background: #5052E2;
}

.layout-menu {
    list-style-type: none;
    margin         : 0;
    padding        : 0;

    li {
        &.layout-menuitem-category {
            margin-top: .75rem;

            &:first-child {
                margin-top: 0;
            }
        }

        .layout-menuitem-root-text {
            text-transform: uppercase;
            color         : var(--surface-900);
            font-weight   : 600;
            margin-bottom : .5rem;
            font-size     : .875rem;
        }

        a {
            cursor         : pointer;
            text-decoration: none;
            display        : flex;
            align-items    : center;
            color          : var(--text-color);
            transition     : color $transitionDuration;
            border-radius  : $borderRadius;
            padding        : .75rem 1rem;
            transition     : background-color .15s;

            span {
                margin-left: 0.5rem;
            }

            .menuitem-toggle-icon {
                margin-left: auto;
            }

            &:focus {
                @include focused-inset();
            }

            &:hover {
                background-color: var(--surface-hover);
            }

            &.router-link-exact-active {
                font-weight: 700;
                color      : var(--primary-color);
            }

            .p-badge {
                margin-left: auto;
            }
        }

        &.active-menuitem {
            >a {
                .menuitem-toggle-icon {
                    &:before {
                        content: '\e933';
                    }
                }
            }
        }

        ul {
            list-style-type: none;
            margin         : 0;
            padding        : 0;

            &.layout-submenu-wrapper-enter {
                max-height: 0;
            }

            &.layout-submenu-wrapper-enter-active {
                overflow  : hidden;
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }

            &.layout-submenu-wrapper-enter-done {
                transform: none;
            }

            &.layout-submenu-wrapper-exit {
                max-height: 1000px;
            }

            &.layout-submenu-wrapper-exit-active {
                overflow  : hidden;
                max-height: 0;
                transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
            }

            ul {
                padding-left: 1rem;
            }
        }
    }
}