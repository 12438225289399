.login_body {
    background-color: #f2fbf8 !important;
    height: 100vh;
}

.p-image-preview-container .round-image {
    border-radius: 25px !important;
}

.p-button.p-button-success:enabled:focus {
    box-shadow: 0 0 0 2px #e0f3ff, 0 0 0 4px #C7D2FE, 0 1px 2px 0 black;
    border-color: #6366F1; /* Optional: Change border color on focus */
}
.round-image {
    border-radius: 25px !important;
}
.clear__button{
    color: white !important;
    background: #5052e2 !important;
}
// .p-button.p-fileupload-choose {
//     padding: 1rem !important;
// }
.w__60 {
    // background-image   : url("./assets/img/Home.png") !important;
    background-repeat: no-repeat;
    // height: 100vh;
    background-size: cover;
    background-position: top;
}

.Action_col .p-column-header-content {
    display: block !important;
}

.Action_col .p-column-header-content .p-column-title {
    text-align: center;
    display: block;
}

.custom___width {
    width: 150px;
}

// .card a{
//     color: #495057 !important;
// }

span a {
    color: #9e9e9e;
}
span a:hover {
    cursor: pointer;
    color: black !important;
}
.custom__class .p-accordion-header-link {
    justify-content: space-between;
}
.grid-container {
    display: grid;
    grid-template-areas:
        "top-header"
        "messagesBox";
    // 'chatBox';
    grid-template-columns: 1fr;
    grid-template-rows: 5rem 1fr;
    height: 80vh;
    // top: 400px;
}

.top-header {
    grid-area: top-header;
}

.messagesBox {
    grid-area: messagesBox;
    overflow-y: auto;
}

.chatBox {
    // height: 6rem;
    // max-height: 10rem;
}

.green {
    color: green;
}

.red {
    color: red;
}

.yellow {
    color: #f1b042;
}

.blue {
    color: blue;
}
.zindigi {
    color: #5ea7a4;
}
.whatsapp_green {
    color: #25d366;
}
.theme_color_blue {
    color: #5052e2;
}
/* Table row background colors */
table.p-datatable-table .bg-success {
    background-color: var(--primary-color) !important;
    /* Green color for active rows */
}

table.p-datatable-table .bg-danger {
    background-color: #d9323286 !important;
    /* Red color for inactive rows */
}

table.p-datatable-table .bg-yellow {
    background-color: #ebf21554 !important;
    /* Red color for inactive rows */
}
table.p-datatable-table .bg-green {
    background-color: #36ec2259 !important;
    /* Red color for inactive rows */
}

/*=======================================================*/
/* Container for the date */
.date-tooltip-container {
    position: relative;
    display: inline-block;
}

/* Initial date shown */
.date-tooltip {
    display: block;
}

/* Full date (hidden initially) */
.date-full-tooltip {
    display: none;
    /* Initially hidden */
    position: absolute;
    top: 100%;
    /* Below the date */
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    width: max-content;
    color: black;
    z-index: 10;
    font-size: 0.9rem;
}

/* On hover, show the full date */
.date-tooltip-container:hover .date-full-tooltip {
    display: block;
    /* Show full date when hovered */
}
/*=======================================================*/

/* Text color for rows based on bg-success or bg-danger */
table.p-datatable-table .bg-success td {
    color: white !important;
    /* White text for active rows */
}

table.p-datatable-table .bg-danger td {
    color: black !important;
    /* Black text for inactive rows */
}
.p-column-header-content .Action_col {
    justify-content: center;
}
.bg_green {
    background-color: green;
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.bg_red {
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.bg_yellow {
    background-color: #f59e0b;
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.p-button-green{
    color: #ffffff;
    background: green;
    border: 1px solid green;
}
.p-button-green:hover{
    color: #ffffff!important;
    background: rgb(0, 92, 0)!important;
    border: 1px solid rgb(0, 92, 0)!important;
}
.bg_zindigi {
    background-color: #5ea7a4;
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.Edit_Icon {
    display: flex;
}

.p-datatable .p-datatable-tbody > .p-datatable-emptymessage > td {
    text-align: center !important;
}
td {
    color: black;
}

.layout-topbar .layout-topbar-logo:focus {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow 0.2s;
    // box-shadow    : var(--focus-ring);
    box-shadow: none !important;
}

.w__60 {
    width: 700px;
    margin: 0 auto;
    box-shadow: 4px 7px 65px 15px #e2eaeb !important;
    padding: 40px 40px 100px 40px;
    margin-top: 7%;
    border-radius: 8px 50px 8px 50px;
}

.button_class {
    text-align: center;
    margin-top: 3rem;
}

.button_class .btn__default {
    width: 150px;
    border-radius: 2rem;
}

.button_class-modal {
    margin: 0 auto;
    text-align: center;
}

.button_class-modal .btn__default_modal {
    width: 200px;
}

.form__mrg__top {
    margin-top: 5rem;
}

.field {
    margin-bottom: 2rem !important;
    width: 90%;
    margin: 0 auto;
}

.custom__style label {
    margin-bottom: 1rem;
}

.Modal_class .field label {
    margin-bottom: 1rem !important;
}

.mbl_view {
    // margin-bottom: .5rem;
    margin-right: 1rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.p-fileupload-choose {
    // width: 200px;
    color: #ffffff;
    background: #5052e2;
    border: 1px solid #5052e2;
    border-radius: 2rem;
    box-shadow: none;
}

.p-fileupload-choose:hover,
.p-fileupload-choose:active,
.p-fileupload-choose:focus {
    width: 200px;
    color: #ffffff !important;
    background: #5052e2 !important;
    border: 1px solid #5052e2 !important;
    border-radius: 2rem;
    box-shadow: none;
    outline: 0;
}

// .padding_left_5r {
//     padding-left: 5rem;
//     padding-top : 2rem;
// }

// .wdth_50 {
//     width: 45%;
//     float: left;
//     padding: .5rem;
// }
.ml__5 {
    padding-left: 7rem;
}

.mbl_mrb {
    margin-right: 0.71rem;
}

.date__input {
    border: 0;
    margin-left: 0.5rem;
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
}

.date__input:hover,
.date__input:active,
.date__input:focus {
    box-shadow: none !important;
    border: 0;
    // margin-left: .5rem;
    background: transparent;
    outline: none !important;
}

.both__date {
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0rem 0rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
}
.p-header-custom > div > table > thead > tr > th > div {
    justify-content: center !important;
}

.reset_password {
    cursor: pointer;
    color: red;
    display: flex;
    justify-content: flex-end;
    width: 80%;
}


//not found page
.h1_not_found{
    color: #5052E2;
    font-size: 10rem;
    // text-shadow: 5px 5px 5px black;
}
.h3_not_found{
    color: black;
    font-weight: 700;
    margin-top: 0.1rem;
    font-size: 1.7rem;
}
@media (max-width: 1366px) and (min-width: 1024px) {
    .w__60 {
        width: 50%;
        margin-top: 5%;
    }
}

@media (max-width: 768px) {
    .p-button.p-button-sm {
        font-size: 0.875rem;
        padding: 0.65625rem 0.09375rem;
    }

    .custom___width {
        width: 150px;
    }

    .mb_width {
        width: 120px !important;
    }

    .mbl_mrb {
        margin-bottom: 0.5rem;
        margin-right: 0 !important;
    }

    .ml__5 {
        padding-left: 0.5rem !important;
    }

    .w__60 {
        width: 90%;
        margin-top: 25%;
    }

    .button_class-modal .btn__default_modal {
        width: 150px !important;
    }

    .mbl_view {
        margin-bottom: 0.5rem;
        margin-right: 0rem;
    }
}

@media (min-width: 1920px) {
    .w__60 {
        margin-top: 10%;
    }
}
.both__date {
    // border: 1px solid;
    // border-radius: 5px;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    // background      : var(--primary-color);
    border: none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 50px;
    // padding: 0.75rem 0.75rem;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    width: 330px;
    float: right;
    // border-radius: 12px;
}

/* Profile.css */
.image-container {
    position: relative;
    border-radius: 50%; /* Make the container a circle */
    overflow: hidden;
}

.image-mask {
    position: relative;
    overflow: hidden;
}

.image-mask img {
    width: 100%;
    height: auto;
    transition: opacity 0.3s;
    border-radius: 50%; /* Make the image a circle */
}

.image-mask::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 50%; /* Make the overlay a circle */
}

.image-mask:hover::before {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; /* Set the text color to white */
    font-size: 16px; /* Adjust the font size as needed */
    content: "Update Profile"; /* Insert "Update Profile" text on hover */
}

/* Add styling for the "Update Profile" text */
.update-text {
    display: none; /* Hide the text initially */
}
td.maker_colm{
    padding-left: 16px !important;
}
span.p-row-toggler-icon.pi.pi-chevron-right {
    color: white;
}
span.p-row-toggler-icon.pi.pi-chevron-down {
    color: white;
}
button.p-row-toggler.p-link {
    background: #4026b9 !important;
}
/* Custom row divider lines */
.p-datatable-gridlines .p-datatable-tbody>tr {
    border-bottom: 1px solid #ffffff;
    /* Adjust the color as needed */
}

.p-datatable-gridlines .p-datatable-tbody>tr:last-child {
    border-bottom: none;
    /* Remove the border from the last row */
}
// .p-dropdown-trigger {
//     width: 2rem !important;
// }

.file-upload-custom .p-fileupload-choose {
    width: auto !important;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
body.modal-open {
    overflow: hidden !important;
    height: 100vh;
}